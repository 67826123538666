<template>
    <div style="height: 100%; overflow-y: auto !important; overflow-x: hidden;">
      <!-- 充电站使用情况情况 -->
      <el-row :gutter="24">
        <el-col :span="12">
          <div>
            <el-card shadow="hover" class="water">
            <template #heihei>
              <div class="card-header">
                <span>Card name</span>
                <el-button class="button" text>Operation button</el-button>
              </div>
            </template>
            <div v-for="o in 4" :key="o" class="text item">
              {{ "List item " + o }}
            </div>
          </el-card>
          </div>
        </el-col>
        <el-col :span="12">
          <div>
            <el-card shadow="hover" class="water">
            <template #header>
              <div class="card-header">
                <span>Card name</span>
                <el-button class="button" text>Operation button</el-button>
              </div>
            </template>
            <div v-for="o in 4" :key="o" class="text item">
              {{ "List item " + o }}
            </div>
          </el-card>
          </div>
        </el-col>
      </el-row>
      <!-- 地图，水图 -->
      <el-row style="margin-top: 24px">
        <el-col :span="19">
          <div id="container"></div>
        </el-col>
        <el-col :span="5">
          <el-card shadow="hover" class="water">
            <template #header>
              <div class="card-header">
                <span>Card name</span>
                <el-button class="button" text>Operation button</el-button>
              </div>
            </template>
            <div v-for="o in 4" :key="o" class="text item">
              {{ "List item " + o }}
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </template>
  
  <script>
  import {
    reactive,
    toRefs,
    getCurrentInstance,
    onMounted,
    nextTick,
    ref,
  } from "vue";
  import AMapLoader from "@amap/amap-jsapi-loader";
  import { shallowRef } from "@vue/reactivity";
  export default {
    name: "EnterprisUser",
    setup() {
      // 可以当this来使用
      const { proxy } = getCurrentInstance();
      const map = shallowRef(null);
  
      const data = reactive({});
  
      // onMounted
      onMounted(async () => {
        //DOM初始化完成进行地图初始化
        methods.initMap();
      });
  
      const methods = {
        initMap() {
          AMapLoader.load({
            key: "adcc38b8290344ae4714f65161f0a25d", // 申请好的Web端开发者Key，首次调用 load 时必填
            version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
          })
            .then((AMap) => {
              console.log("查看AMap", AMap);
              this.map = new AMap.Map("container", {
                //设置地图容器id
                viewMode: "3D", //是否为3D地图模式
                zoom: 13, //初始化地图级别
                center: [113.9197423, 22.5437855], //初始化地图中心点位置
              });
  
              // let marker1 = new AMap.Marker({
              //   position: new AMap.LngLat(113.9197423,22.5437855),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
              //   title: '北京'
              // });
              // let marker2 = new AMap.Marker({
              //   position: new AMap.LngLat(113.9897434,22.5437866),
              //   title: '北京2'
              // })
  
              let list = [
                {
                  positionX: "113.9197423",
                  positionY: "22.5437855",
                  title: "北京1",
                },
                {
                  positionX: "113.9897434",
                  positionY: "22.5437866",
                  title: "北京2",
                },
              ];
  
              let markerList = [];
  
              list.forEach((item, index) => {
                let marker = new AMap.Marker({
                  position: new AMap.LngLat(item.positionX, item.positionY),
                  title: item.title,
                });
                markerList.push(marker);
              });
  
              console.log("悄悄markerList", markerList);
  
              this.map.add(markerList);
  
              console.log("查看this.map", proxy.map);
            })
            .catch((e) => {
              console.log(e);
            });
        },
      };
  
      return {
        ...methods,
        map,
      };
    },
  };
  </script>
  <style lang="less" scoped>
  #container {
    padding: 0px;
    margin: 0px;
    width: 98%;
    height: 550px;
  }
  .water {
    height: 550px;
  }
  
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .text {
    font-size: 14px;
  }
  
  .item {
    margin-bottom: 18px;
  }
  
  // .warp {
  //   height: 100%;
  //   .warp-content {
  //     height: 100%;
  //     .main {
  //       height: 550px;
  //       padding: 24px;
  //     }
  //   }
  // }
  </style>
  